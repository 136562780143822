/* ============================================================================
	Fonts
============================================================================ */
@font-face {
	font-family:roboto;
	font-style:normal;
	font-weight:400;
	src:url('../fonts/roboto/roboto-regular.woff2') format('woff2'),
	url('../fonts/roboto/roboto-regular.woff') format('woff'),
	url('../fonts/roboto/roboto-regular.ttf') format('truetype');
}

@font-face {
	font-family:roboto;
	font-style:italic;
	font-weight:400;
	src:url('../fonts/roboto/roboto-italic.woff2') format('woff2'),
	url('../fonts/roboto/roboto-italic.woff') format('woff'),
	url('../fonts/roboto/roboto-italic.ttf') format('truetype');
}

@font-face {
	font-family:roboto;
	font-style:normal;
	font-weight:500;
	src:url('../fonts/roboto/roboto-medium.woff2') format('woff2'),
	url('../fonts/roboto/roboto-medium.woff') format('woff'),
	url('../fonts/roboto/roboto-medium.ttf') format('truetype');
}

@font-face {
	font-family:roboto;
	font-style:italic;
	font-weight:500;
	src:url('../fonts/roboto/roboto-mediumitalic.woff2') format('woff2'),
	url('../fonts/roboto/roboto-mediumitalic.woff') format('woff'),
	url('../fonts/roboto/roboto-mediumitalic.ttf') format('truetype');
}

@font-face {
	font-family:roboto;
	font-style:normal;
	font-weight:600;
	src:url('../fonts/roboto/roboto-medium.woff2') format('woff2'),
	url('../fonts/roboto/roboto-medium.woff') format('woff'),
	url('../fonts/roboto/roboto-medium.ttf') format('truetype');
}

@font-face {
	font-family:roboto;
	font-style:italic;
	font-weight:600;
	src:url('../fonts/roboto/roboto-mediumitalic.woff2') format('woff2'),
	url('../fonts/roboto/roboto-mediumitalic.woff') format('woff'),
	url('../fonts/roboto/roboto-mediumitalic.ttf') format('truetype');
}

@font-face {
	font-family:roboto;
	font-style:normal;
	font-weight:700;
	src:url('../fonts/roboto/roboto-bold.woff2') format('woff2'),
	url('../fonts/roboto/roboto-bold.woff') format('woff'),
	url('../fonts/roboto/roboto-bold.ttf') format('truetype');
}

@font-face {
	font-family:roboto;
	font-style:italic;
	font-weight:700;
	src:url('../fonts/roboto/roboto-bolditalic.woff2') format('woff2'),
	url('../fonts/roboto/roboto-bolditalic.woff') format('woff'),
	url('../fonts/roboto/roboto-bolditalic.ttf') format('truetype');
}
