@import '../vars';
@import '../mixins/media';

.template-login {

	/* ============================================================================
		Content
	============================================================================ */
	.site-content {
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		background-color: #012732;
		padding:30px 20px;
		.site-content-logo {
			margin-bottom:40px;
			img {
				height:80px;
			}
		}
		.site-content-inner {
			width:100%;
			max-width:350px;
			.page-content {
				button {
					box-shadow: 0 2px 6px rgb(0 201 255 / 0%) !important;

					&:hover {
						background-color: #FF33CA !important;
					}
				}

				h5 {
					color: white !important;
					font-size:16px;
					text-align: center;
					font-weight:400;

				}
			}
		}
	}

}
