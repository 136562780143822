.site-content-inner.page-account {
	.page-content {
		.ant-form {
			max-width:940px;
			.avatar {
				margin-bottom:30px;
				.avatar-uploader {
					&.not-sending {
						> .ant-upload {
							.ant-progress {
								.ant-progress-inner {
									.ant-progress-text {
										display:none;
									}
								}
							}
						}
					}
					> .ant-upload {
						position:relative;
						width:170px;
						height:170px;
						overflow:hidden;
						border-radius:50%;
						.ant-upload-text {
							margin-top:8px;
						}
						.anticon {
							font-size:32px;
							color:#999;
						}
						img {
							border-radius:50%;
						}
						.ant-progress {
							position:absolute;
							top:0;
							left:0;
							transition:opacity 1000ms linear;
							&.ant-progress-status-success {
								opacity:0;
							}
							.ant-progress-inner {
								.ant-progress-circle-trail {
									stroke:transparent;
								}
								.ant-progress-text {
									color:#fff;
								}
							}
						}
					}
				}
			}
			.roles {
				.ant-tag {
					margin-bottom:8px;
				}
			}
		}
	}
}
