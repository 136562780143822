// Base Class Definition
// -------------------------

.#{$fa-css-prefix},
.fas,
.far,
.fal,
.fad,
.fab {
	-moz-osx-font-smoothing:grayscale;
	-webkit-font-smoothing:antialiased;
	display:inline-block;
	font-style:normal;
	font-variant:normal;
	text-rendering:auto;
	line-height:1;
}

%fa-icon {
	@include fa-icon;
}
